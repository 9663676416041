import NavLinks from "./NavLinks";

const Header = () => {
  return (
    <header className="header">
      {/* <a href="default.asp"> */}
        {/* <img className="logo" src={logo} alt="logo" /> */}
      <NavLinks />
      {/* </a> */}
    </header>
  );
};

export default Header;
